import React from 'react'

const Footer = () => {
    return (
        <>
            <footer>
                <div className='container'>
                    <div className='row'>
                        <p>Copyright© Harry Walton Boone. All Rights Reserved.</p>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer
